import { SelfStorage__Classification } from '@admin/schema';
import * as React from 'react';

export const Size: React.FC<{
  classification: Pick<SelfStorage__Classification, 'length' | 'width' | 'height'>;
}> = ({ classification }) => (
  <>
    {classification.width}′ × {classification.length}′ × {classification.height}′
  </>
);

import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import {
  Workforce__PtoRequestGroupReview__Input,
  usePtoRequestGroupQuery,
  usePtoRequestGroupReviewMutation,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Button, Panel, Modal, Alert } from '@shared/components/bootstrap';
import { FormikRadioFormGroup } from '@admin/components/fields/formik/formik_radio_form_group';
import { DateTime } from 'luxon';
import { Pluralize, Spacer } from '@shared/components/helpers';
import { Text } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';

const PTORequestGroupApproveRejectForm: React.FC<{
  selectedRequestGroupID: string;
  onClose(): void;
}> = ({ selectedRequestGroupID, onClose }) => {
  const { data, loading } = usePtoRequestGroupQuery({ client, variables: { id: selectedRequestGroupID } });
  const ptoRequestGroup = data?.ptoRequestGroup;

  const [save, { loading: saving }] = usePtoRequestGroupReviewMutation({
    client,
    onCompleted: () => {
      onClose();
    },
  });

  if (!ptoRequestGroup) return <Spinner />;

  const onSubmit = (input: Workforce__PtoRequestGroupReview__Input) => {
    save({
      variables: {
        input,
      },
    });
  };

  const conflicts = ptoRequestGroup.requests.filter((request) => request.conflictingShiftAssignmentExists);

  return (
    <Formik<Workforce__PtoRequestGroupReview__Input>
      initialValues={{
        requestGroupID: selectedRequestGroupID,
        approve: true,
        note: '',
      }}
      onSubmit={onSubmit}
    >
      <FormikForm>
        <Panel>
          <Panel.Body>
            <p className="h4">
              {ptoRequestGroup.user.name} is requesting {ptoRequestGroup.totalHours} hours of{' '}
              {ptoRequestGroup.kind.name} PTO:
            </p>
            <Spacer height="16px" />
            <ul>
              {ptoRequestGroup.requests.map((request, i) => (
                <li key={i}>
                  {DateTime.fromISO(request.requestDate).toLocaleString(DateTime.DATE_MED)} - {request.hours} Hours{' '}
                  {request.conflictingShiftAssignmentExists && (
                    <Text style="danger">(conflicts w/ scheduled shift)</Text>
                  )}
                </li>
              ))}
            </ul>
            <FormikRadioFormGroup
              name="approve"
              options={[
                {
                  value: true,
                  label: 'Approve',
                },
                {
                  value: false,
                  label: 'Reject',
                },
              ]}
            />
            <FormikInputFormGroup label="Note (Optional)" name="note" />

            {conflicts.length > 0 && (
              <Alert style="warning">
                This employee has <Pluralize count={conflicts.length} singular="shift" plural="shifts" /> conflicting
                with this request. If approved the following shifts will be deleted:
                <ul>
                  {conflicts.map((request, i) => (
                    <li key={i}>{DateTime.fromISO(request.requestDate).toLocaleString(DateTime.DATE_MED)}</li>
                  ))}
                </ul>
              </Alert>
            )}
          </Panel.Body>
          <Panel.Footer align="right">
            <Button kind="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button loading={loading || saving} kind="primary" type="submit">
              Submit
            </Button>
          </Panel.Footer>
        </Panel>
      </FormikForm>
    </Formik>
  );
};

export const ReviewModal: React.FC<{
  selectedRequestGroupID: string;
  onClose(): void;
}> = ({ selectedRequestGroupID, onClose }) => (
  <Modal onClose={onClose}>
    <PTORequestGroupApproveRejectForm selectedRequestGroupID={selectedRequestGroupID} onClose={onClose} />
  </Modal>
);

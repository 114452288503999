(function () {
  const app = angular.module('app');

  app.controller('VehicleShowController', [
    '$scope',
    '$stateParams',
    'Vehicle',
    function ($scope, $stateParams, Vehicle) {
      $scope.id = $stateParams.id;
      $scope.vehicle = Vehicle.get({ id: $scope.id });
    },
  ]);

  app.controller('VehicleEditController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'Vehicle',
    'Setup',
    function ($rootScope, $scope, $state, $stateParams, Vehicle, Setup) {
      $scope.setup = Setup.get();
      $scope.vehicle = Vehicle.get({ id: $stateParams.id });
      $scope.vehicle.$promise.then(() => {
        if ($scope.vehicle.registration_expires_on) {
          $scope.vehicle.registration_expires_on = new Date($scope.vehicle.registration_expires_on);
        }
        if ($scope.vehicle.inspection_expires_on) {
          $scope.vehicle.inspection_expires_on = new Date($scope.vehicle.inspection_expires_on);
        }
        if ($scope.vehicle.maintenance_due_on) {
          $scope.vehicle.maintenance_due_on = new Date($scope.vehicle.maintenance_due_on);
        }
      });
      $scope.filters = {
        warehouse_ids: $stateParams.warehouse_ids,
        status: $stateParams.status,
        vehicle_type_id: $stateParams.vehicle_type_id,
      };
      $scope.save = function () {
        $scope.vehicle.$update(
          () => {
            $state.go('vehicles', {
              warehouse_ids: $stateParams.warehouse_ids,
              status: $stateParams.status,
              vehicle_type_id: $stateParams.vehicle_type_id,
            });
          },
          (error) => {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);

  app.controller('VehicleCreateController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'Vehicle',
    'Setup',
    function ($rootScope, $scope, $state, $stateParams, Vehicle, Setup) {
      $scope.setup = Setup.get();
      $scope.vehicle = new Vehicle();
      $scope.vehicle.status = 'active';
      $scope.filters = {
        warehouse_ids: $stateParams.warehouse_ids,
        status: $stateParams.status,
        vehicle_type_id: $stateParams.vehicle_type_id,
      };
      $scope.addVehicle = function () {
        $scope.vehicle.$save(
          () => {
            $state.go('vehicles', {
              warehouse_ids: $stateParams.warehouse_ids,
              status: $stateParams.status,
              vehicle_type_id: $stateParams.vehicle_type_id,
            });
          },
          (error) => {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);
})();

import React from 'react';

import { SelfStorage__Classification } from '@admin/schema';

export const UnitClimate: React.FC<{
  unit: {
    classification: Pick<SelfStorage__Classification, 'climate'>;
  };
}> = ({ unit: { classification } }) => {
  const climate = classification.climate;
  return climate ? <>Climate Controlled</> : <>None</>;
};

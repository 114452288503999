import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Pto__RequestFiltersInput, Pto__RequestState, usePaginatedPtoRequestGroupsQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table, Button, Row, Col } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';
import { DateTime } from 'luxon';
import { capitalize, compact } from 'lodash';
import { Label } from '@admin/components/helpers/label';
import { Pagination } from '@admin/components/pagination';
import { Spacer } from '@shared/components/helpers';
import { FieldFormGroup, MultiselectFormControl } from '@admin/components/fields';
import { ReviewModal } from './review_modal';

const REQUEST_STATE_TO_LABEL_KIND: Record<Pto__RequestState, 'success' | 'default' | 'danger' | 'warning'> = {
  [Pto__RequestState.Pending]: 'warning',
  [Pto__RequestState.Approved]: 'success',
  [Pto__RequestState.Rejected]: 'danger',
  [Pto__RequestState.Canceled]: 'default',
};

const Filters: React.FC<{
  filters: Pto__RequestFiltersInput;
  teamOptions: Array<{ id: string; name: string }>;
  userOptions: Array<{ id: string; name: string }>;
  stateOptions: Array<{ id: string; name: string; value: Pto__RequestState }>;
  setFilters(filters: Pto__RequestFiltersInput): void;
}> = ({ filters, teamOptions, userOptions, stateOptions, setFilters }) => (
  <Row>
    <Col md={3}>
      <FieldFormGroup label="User:">
        <MultiselectFormControl
          placeholder="User"
          options={userOptions}
          selectedIDs={filters.userIDs ?? []}
          onChange={(userIDs) =>
            setFilters({
              ...filters,
              userIDs,
            })
          }
        />
      </FieldFormGroup>
    </Col>
    <Col md={3}>
      <FieldFormGroup label="Team:">
        <MultiselectFormControl
          placeholder="Team"
          options={teamOptions}
          selectedIDs={filters.teamIDs ?? []}
          onChange={(teamIDs) =>
            setFilters({
              ...filters,
              teamIDs,
            })
          }
        />
      </FieldFormGroup>
    </Col>
    <Col md={3}>
      <FieldFormGroup label="State:">
        <MultiselectFormControl
          placeholder="State"
          options={stateOptions}
          selectedIDs={
            compact(
              filters.states?.map((value) => stateOptions.find((stateOption) => stateOption.value === value)?.id),
            ) ?? []
          }
          onChange={(stateIDs) =>
            setFilters({
              ...filters,
              states: compact(
                stateIDs.map((stateID) => stateOptions.find((stateOption) => stateOption.id === stateID)?.value),
              ),
            })
          }
        />
      </FieldFormGroup>
    </Col>
  </Row>
);

export const PTORequestsList: React.FC<{ userID?: string }> = ({ userID }) => {
  const initialFilterState = {
    states: [Pto__RequestState.Pending],
    userIDs: userID ? [userID] : undefined,
    teamIDs: undefined,
  };

  const [filters, setFilters] = useState<Pto__RequestFiltersInput>(initialFilterState);
  const [selectedRequestGroupID, setSelectedRequestGroupID] = useState<string | undefined>(undefined);

  const { data, loading, refetch } = usePaginatedPtoRequestGroupsQuery({
    client,
    variables: {
      filters,
    },
  });

  const ptoRequestGroups = data?.paginatedPtoRequestGroups.results;

  const stateOptions = [
    Pto__RequestState.Pending,
    Pto__RequestState.Approved,
    Pto__RequestState.Rejected,
    Pto__RequestState.Canceled,
  ].map((option) => ({ id: capitalize(option), name: capitalize(option), value: option }));

  return (
    <>
      <Panel>
        <Panel.Body>
          <Filters
            filters={filters}
            teamOptions={data?.teams ?? []}
            userOptions={data?.users ?? []}
            stateOptions={stateOptions}
            setFilters={setFilters}
          />
          <Spacer height="16px" />
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-1">User</th>
                <th className="col-sm-1">Team</th>
                <th className="col-sm-1">PTO Kind</th>
                <th className="col-sm-1">PTO Date</th>
                <th className="col-sm-1">PTO Hours</th>
                <th className="col-sm-1">Submission Time</th>
                <th className="col-sm-1">Status</th>
              </tr>
            </thead>

            <tbody>
              {ptoRequestGroups?.map((ptoRequestGroup) => (
                <tr key={ptoRequestGroup.id}>
                  <td className="col-sm-1">{ptoRequestGroup.user.name}</td>
                  <td className="col-sm-1">{ptoRequestGroup.user.team?.name}</td>
                  <td className="col-sm-1">{ptoRequestGroup.kind.name}</td>
                  <td className="col-sm-1">
                    {ptoRequestGroup.startDate === ptoRequestGroup.endDate
                      ? DateTime.fromISO(ptoRequestGroup.startDate).toLocaleString(DateTime.DATE_MED)
                      : `${DateTime.fromISO(ptoRequestGroup.startDate).toLocaleString(
                          DateTime.DATE_MED,
                        )} - ${DateTime.fromISO(ptoRequestGroup.endDate).toLocaleString(DateTime.DATE_MED)}`}
                  </td>
                  <td className="col-sm-1">{ptoRequestGroup.totalHours}</td>
                  <td className="col-sm-1">
                    {DateTime.fromISO(ptoRequestGroup.createdAt).toLocaleString(DateTime.DATETIME_MED)}
                  </td>
                  <td className="col-sm-1">
                    {ptoRequestGroup.state === Pto__RequestState.Pending ? (
                      <Button
                        kind="primary"
                        onClick={() => {
                          setSelectedRequestGroupID(ptoRequestGroup.id);
                        }}
                      >
                        Review
                      </Button>
                    ) : (
                      <>
                        <Label kind={REQUEST_STATE_TO_LABEL_KIND[ptoRequestGroup.state]}>
                          {capitalize(ptoRequestGroup.state)}
                        </Label>
                        {(ptoRequestGroup.state === Pto__RequestState.Approved ||
                          ptoRequestGroup.state === Pto__RequestState.Rejected) && (
                          <p>{ptoRequestGroup.reviewerNote}</p>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
        </Panel.Body>
      </Panel>
      {data?.paginatedPtoRequestGroups && (
        <Pagination
          pagination={data.paginatedPtoRequestGroups.pagination}
          onPage={(page) => refetch({ filters, page })}
        />
      )}
      {selectedRequestGroupID && (
        <ReviewModal
          selectedRequestGroupID={selectedRequestGroupID}
          onClose={() => {
            setSelectedRequestGroupID(undefined);
            refetch();
          }}
        />
      )}
    </>
  );
};

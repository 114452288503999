import { SelfStorage__Classification } from '@admin/schema';
import React from 'react';

import { Size } from '@admin/components/self_storage/classifications/size';

export const UnitSize: React.FC<{
  unit: {
    classification: Pick<SelfStorage__Classification, 'width' | 'length' | 'height'>;
  };
}> = ({ unit: { classification } }) => <Size classification={classification} />;

import React from 'react';

import { SelfStorage__Unit, SelfStorage__Classification } from '@admin/schema';
import { getOrdinalSuffix } from '@shared/utils';

const UnitFloor: React.FC<{
  unit: Pick<SelfStorage__Unit, 'floor'>;
}> = ({ unit }) => {
  if (!unit.floor) return null;
  if (unit.floor === 0) return <>Ground Floor</>;

  return (
    <>
      {unit.floor} {getOrdinalSuffix(unit.floor)} Floor
    </>
  );
};

const UnitIndoorsOutdoors: React.FC<{
  unit: { classification: Pick<SelfStorage__Classification, 'indoors'> };
}> = ({ unit }) => (unit.classification.indoors ? <>Indoors</> : <>Outdoors</>);

export const UnitLocation: React.FC<{
  unit: Pick<SelfStorage__Unit, 'floor'> & {
    classification: Pick<SelfStorage__Classification, 'indoors'>;
  };
}> = ({ unit }) => (
  <>
    <UnitIndoorsOutdoors unit={unit} /> <UnitFloor unit={unit} />
  </>
);

import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Summary as PromotionSummary } from '@admin/components/self_storage/promotions/summary';
import { Pagination } from '@admin/components/pagination';
import { COLORS } from '@root/colors';
import { SearchInput, Table } from '@shared/components/bootstrap';
import { Currency, Spacer } from '@shared/components/helpers';
import {
  Maybe,
  SelfStorage__ReservationFragment,
  SelfStorage__Unit__FiltersInput,
  SelfStorage__PromotionFragment,
  usePaginatedSelfStorageUnitsQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { useDebounce } from '@shared/hooks';
import { SectionHeading } from '../components/section_heading';
import {
  ClimateFormGroup,
  CUFTFormGroup,
  DimensionsFormGroup,
  FloorFormGroup,
  IndoorsFormGroup,
  KindFormGroup,
  ObstructedFormGroup,
  PriceFormGroup,
} from '../units/filters';
import { UnitClimate } from '../units/unit_climate';
import { UnitLocation } from '../units/unit_location';
import { UnitSize } from '../units/unit_size';

const SectionWrapper = styled.div`
  font-size: 13px;
`;

const FirstFilterRow = styled.div`
  margin-top: -23px;
`;

const SearchWrapper = styled.div`
  padding-top: 23px;
  margin-bottom: 15px;
`;

const ToggleFiltersWrapper = styled.div`
  padding-top: 29px;
  margin-bottom: 15px;
`;

const TableHeader = styled.th`
  font-size: 14px;
`;

const TableRow = styled.tr<{ selected: boolean }>`
  ${({ selected }) =>
    selected &&
    `
    background-color: ${COLORS.blueLightest} !important;
    font-weight: 600;
  `}
`;

const ButtonWrapper = styled.div`
  input {
    display: none;
  }
`;

const HighlightPrice = styled.div`
  color: ${COLORS.orange};
  font-weight: 600;
`;

const ActionColumn = styled.td<{ error: boolean }>`
  ${({ error }) => error && 'background-color: #a946441c'}
`;

const Error = styled.div`
  color: #a94442;
`;

const Price: React.FC<{ price?: Maybe<number>; reservationPrice?: Maybe<number> }> = ({ price, reservationPrice }) => {
  if (!price) {
    return null;
  }

  const priceLabel = <Currency value={price} />;
  return reservationPrice && reservationPrice < price ? <HighlightPrice>{priceLabel}</HighlightPrice> : priceLabel;
};

const Promotion: React.FC<{
  promotion?: Maybe<SelfStorage__PromotionFragment>;
  reservationPromotion?: Maybe<SelfStorage__PromotionFragment>;
}> = ({ promotion, reservationPromotion }) => {
  if (!promotion) {
    return reservationPromotion ? <HighlightPrice>None</HighlightPrice> : <>None</>;
  }

  const promotionLabel = <PromotionSummary promotion={promotion} />;
  return reservationPromotion && reservationPromotion.id !== promotion.id ? (
    <HighlightPrice>{promotionLabel}</HighlightPrice>
  ) : (
    promotionLabel
  );
};

export const UnitSelector: React.FC<{
  reservation?: SelfStorage__ReservationFragment;
  facilityID?: string;
}> = ({ reservation, facilityID }) => {
  const [page, setPage] = useState<number | undefined>(undefined);
  const [filters, setFilters] = useState<SelfStorage__Unit__FiltersInput>({ search: '' });
  const [showAllUnits, setShowAllUnits] = useState(false);
  const { register, unregister, errors, setValue, formState, watch } = useFormContext<{ unitID?: string }>();

  const { data } = usePaginatedSelfStorageUnitsQuery({
    client,
    variables: {
      page,
      filters: {
        ...useDebounce(filters),
        reservable: true,
        facilityIDs: [reservation?.facility.id ?? facilityID!],
        classificationIDs: !showAllUnits && reservation?.classification ? [reservation.classification.id] : undefined,
      },
    },
  });
  const units = data?.paginated?.results ?? [];
  const pagination = data?.paginated?.pagination;
  const reservationPrice = reservation?.price?.quotedRate;
  const unitID = watch('unitID');

  useEffect(() => {
    register({ name: 'unitID' }, { required: true });
    return () => {
      unregister('unitID');
    };
  }, [typeof register]);

  const setUnitID = (id: string | undefined) => {
    setValue('unitID', id, formState.isSubmitted);
  };

  const handlePage = (newPage: number) => {
    setPage(newPage);
    setUnitID(undefined);
  };

  const onFilter = (newFilters: SelfStorage__Unit__FiltersInput) => {
    setFilters({ ...filters, ...newFilters });
    setPage(undefined);
    setUnitID(undefined);
  };

  const toggleAllUnits = () => {
    if (showAllUnits) {
      setFilters({ search: filters.search });
    }
    setShowAllUnits(!showAllUnits);
    setPage(undefined);
    setUnitID(undefined);
  };

  useEffect(() => {
    setPage(undefined);
    setUnitID(undefined);
  }, [facilityID]);

  return (
    <SectionWrapper>
      <SectionHeading>Select a Unit</SectionHeading>
      {(reservation || facilityID) && (
        <FirstFilterRow className="row">
          <SearchWrapper className="col-md-3 col-sm-6">
            <SearchInput
              size={36}
              value={filters.search || undefined}
              onChange={(e) => onFilter({ search: e.target.value })}
            />
          </SearchWrapper>
          {!showAllUnits && !facilityID ? (
            <ToggleFiltersWrapper className="col-md-2">
              <button className="btn-link" type="button" onClick={toggleAllUnits}>
                Show all units
              </button>
            </ToggleFiltersWrapper>
          ) : (
            <>
              <div className="col-md-3 col-sm-6">
                <PriceFormGroup price={filters.price} onChange={(price) => onFilter({ price })} />
              </div>
              <div className="col-md-3 col-sm-6">
                <CUFTFormGroup
                  cuft={filters.classification && filters.classification.cuft}
                  onChange={(cuft) => onFilter({ classification: { ...filters.classification, cuft } })}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <DimensionsFormGroup
                  classification={filters.classification}
                  onChange={(classification) => onFilter({ classification })}
                />
              </div>
            </>
          )}
        </FirstFilterRow>
      )}
      {(showAllUnits || facilityID) && (
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <KindFormGroup
              kind={filters.classification && filters.classification.kind}
              onChange={(kind) => onFilter({ classification: { kind } })}
            />
          </div>
          <div className="col-md-2 col-sm-6">
            <FloorFormGroup floors={filters.floors} onChange={(floors) => onFilter({ floors })} />
          </div>
          <div className="col-md-2 col-sm-6">
            <ClimateFormGroup
              climate={filters.classification && filters.classification.climate}
              onChange={(climate) => onFilter({ ...filters, classification: { climate } })}
            />
          </div>
          <div className="col-md-2 col-sm-6">
            <ObstructedFormGroup
              obstructed={filters.classification && filters.classification.obstructed}
              onChange={(obstructed) => onFilter({ classification: { ...filters.classification, obstructed } })}
            />
          </div>
          <div className={!facilityID ? 'col-md-2 col-sm-6' : 'col-md-3 col-sm-6'}>
            <IndoorsFormGroup
              indoors={filters.classification && filters.classification.indoors}
              onChange={(indoors) => onFilter({ classification: { ...filters.classification, indoors } })}
            />
          </div>
          {!facilityID && (
            <ToggleFiltersWrapper className="col-md-1 col-sm-6">
              <button className="btn-link" type="button" onClick={toggleAllUnits}>
                Reset
              </button>
            </ToggleFiltersWrapper>
          )}
        </div>
      )}
      <Spacer height="24px" />
      <Table striped responsive>
        <thead>
          <tr>
            <TableHeader>Facility</TableHeader>
            <TableHeader>Unit Type</TableHeader>
            <TableHeader>Unit Size</TableHeader>
            <TableHeader>Floor</TableHeader>
            <TableHeader>Location</TableHeader>
            <TableHeader>Climate</TableHeader>
            <TableHeader>Price</TableHeader>
            <TableHeader>Promotion</TableHeader>
            <TableHeader>Unit Number</TableHeader>
            <TableHeader>Action</TableHeader>
          </tr>
        </thead>
        <tbody>
          {reservation || facilityID ? (
            units.map((unit) => {
              const price = unit.price && unit.price.quotedRate;
              return (
                <TableRow key={unit.id} selected={unit.id === unitID}>
                  <td>{unit.facility && unit.facility.name}</td>
                  <td>{unit.classification && unit.classification.kind && unit.classification.kind.name}</td>
                  <td>
                    <UnitSize unit={unit} />
                  </td>
                  <td>{unit.floor}</td>
                  <td>
                    <UnitLocation unit={unit} />
                  </td>
                  <td>
                    <UnitClimate unit={unit} />
                  </td>
                  <td>
                    <Price price={price} reservationPrice={reservationPrice} />
                  </td>
                  <td>
                    <Promotion promotion={unit.promotion} reservationPromotion={reservation?.promotion} />
                  </td>
                  <td>{unit.name}</td>
                  <ActionColumn error={!!errors.unitID}>
                    <ButtonWrapper>
                      <input
                        id={unit.id}
                        type="radio"
                        name="unitID"
                        value={unit.id}
                        checked={unit.id === unitID}
                        onChange={(e) => {
                          setUnitID(e.target.value);
                        }}
                      />
                      <label
                        htmlFor={unit.id}
                        className={`btn ${unit.id !== unitID ? 'btn-primary' : 'btn-info disabled'}`}
                      >
                        Select
                      </label>
                    </ButtonWrapper>
                  </ActionColumn>
                </TableRow>
              );
            })
          ) : (
            <tr>
              <td colSpan={10}>Please select a facility.</td>
            </tr>
          )}
          {errors.unitID && (
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>
                <Error>Please select a unit.</Error>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {pagination && <Pagination pagination={pagination} onPage={handlePage} />}
      <Spacer height="36px" />
    </SectionWrapper>
  );
};
